<template>
  <v-container>
      <v-stepper v-model="e1">
          
          <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1">
                Verifica codigo
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 1"
                step="2">
                Cambia codigo
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
              <v-stepper-content step="1" class="px-0">
                  <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col class="text-center">
                                Verifica tu codigo
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-row class="px-6">
                        <v-col cols="2" class="px-1">
                            <v-text-field id="tx0" dropzone="false" @keyup="SiguienteNumero" solo autofocus v-model="codigo[0]"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="px-1">
                            <v-text-field id="tx1" @keyup="SiguienteNumero" solo v-model="codigo[1]"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="px-1">
                            <v-text-field id="tx2" @keyup="SiguienteNumero" solo v-model="codigo[2]"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="px-1">
                            <v-text-field id="tx3" @keyup="SiguienteNumero" solo v-model="codigo[3]"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="px-1">
                            <v-text-field id="tx4" @keyup="SiguienteNumero" solo v-model="codigo[4]"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="px-1">
                            <v-text-field id="tx5" @keyup="SiguienteNumero" solo v-model="codigo[5]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-actions class="d-flex flex-row-reverse">
                        <v-btn color="primary" @click="NextPage">Siguiente</v-btn>
                        <v-btn text to="/OlvidaContra">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2" class="px-0">
                  <v-card>

                      <v-card-title>Nueva Contraseña</v-card-title>
                      <v-text-field 
                        solo 
                        class="mx-4" 
                        v-model="contra"
                        :rules="[v=> v!=='' || 'Obligatorio']"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        ></v-text-field>
                      
                      <v-card-title>Repite Contraseña</v-card-title>
                      <v-text-field 
                        solo 
                        class="mx-4" 
                        v-model="contra2"
                        type="password"
                        :rules="[v=> v!=='' || 'Obligatorio',(contra === contra2) || 'No Coincide la contraseña']"
                        ></v-text-field>
                      
                      <v-card-actions class="d-flex flex-row-reverse">
                          <v-btn color="primary" @click="CambiarContra" :disabled="verificaContra()">Guardar</v-btn>
                          <v-btn text @click="e1=1">Cancelar</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-stepper-content>
          </v-stepper-items>

      </v-stepper>
      
  </v-container>
</template>

<script>
export default {
    data(){
        return{
            codigo: ['','','','','',''],
            contador: 3,
            e1: 1,
            contra: '',
            contra2: '',
            show1: false
        }
    },
    created(){
      this.$emit('muestra', true)
    },
    methods:{
        SiguienteNumero: function (){
            if (this.codigo[0] === '') {
                document.getElementById('tx0').focus()
            }else if(this.codigo[1] === ''){
                document.getElementById('tx1').focus()
            }else if(this.codigo[2] === ''){
                document.getElementById('tx2').focus()
            }else if(this.codigo[3] === ''){
                document.getElementById('tx3').focus()
            }else if(this.codigo[4] === ''){
                document.getElementById('tx4').focus()
            }else if(this.codigo[5] === ''){
                document.getElementById('tx5').focus()
            }
            this.codigo.forEach((v,i)=>{
                if (v.length>0) {
                    this.codigo[i] = v[0]
                }
            })
        },
        CheckCodigo: async function(){
            const body = {
                opcion: 18,
                codigo: this.codigo.join(''),
                email: this.$route.params.email
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            return data
        },
        cambiarCodigo: async function(codigo){
            const body2 = {
                email: this.$route.params.email,
                codigo: codigo,
                opcion: 17
            }
            const params2 = {
                body: JSON.stringify(body2),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php',params2)
            const data = await response.json()
            return data
        },
        sendEmail: async function(codigo){
            const body3 = {
                email: this.$route.params.email,
                codigo: codigo
            }
            const params3 = {
                body: JSON.stringify(body3),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/enviarCorreo.php', params3)
            const data = await response
            return data
        },
        NextPage: function(){
            this.CheckCodigo().then((d)=>{
                if (d.length!==0) {
                    this.e1 = 2
                }else{
                    this.contador--
                    if (this.contador<=0) {
                        alert('Excediste el numero de intentos, por seguridad enviaremos otro codigo a tu correo electronico')

                        //Crea un numero random
                        var arreglo = new Uint32Array(1)
                        var arreglo2 = []
                        window.crypto.getRandomValues(arreglo)
                        arreglo[0].toString().split('').forEach((value, index)=>{
                            if (index < 6) {
                                arreglo2.push(value)
                            }
                        })
                        const cod = arreglo2.join('')

                        this.cambiarCodigo(cod).then(()=>{

                            this.sendEmail(cod).then(()=>{
                                this.contador = 3
                            }).catch(e=>{
                                alert('Hubo un error al enviar el correo')
                            })

                        }).catch(er=>{
                            alert('Hubo un error al enviar el correo')
                        })
                    }
                }
            })
        },
        CambiarContra: function(){
            var md5 = require('md5')
            var cont = md5(this.contra)
            const body = {
                email: this.$route.params.email,
                contra: cont,
                opcion: 19
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            this.apiCambiaContra(params).then(d=>{
                this.$router.push('/Login')
            })
        },
        apiCambiaContra: async function(params){
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            return data
        },
        verificaContra: function(){
            return this.contra==='' || this.contra2==='' || this.contra !== this.contra2
        }
    }
}
</script>

<style>

</style>